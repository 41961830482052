:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Free";
  --fa-font-solid: normal 900 1em / 1 "Font Awesome 6 Free";
}

@font-face {
  font-family: "Font Awesome 6 Free";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("fa-solid-900.977e2c2d.woff2") format("woff2"), url("fa-solid-900.a3c6e01f.ttf") format("truetype");
}

.fas, .fa-solid {
  font-weight: 900;
}

/*# sourceMappingURL=index.49a8b75f.css.map */
